<template>
  <StatusFilterList
    v-model="params.status"
    v-bind="params"
    @change="change"
    :list="list"
  >
    <div class="text-right" slot="opt">
      <a-space :size="20">
        <gf-re-input-search
          @search="change({ name: $event, current: 1 })"
          style="width: 360px"
        >
          <a-select slot="addonBefore" style="width: 110px" v-model="nameType">
            <a-select-option :value="0">需求名称</a-select-option>
            <a-select-option :value="1">公司名称</a-select-option>
          </a-select>
        </gf-re-input-search>
      </a-space>
    </div>
    <ProCard
      type="supply"
      :to="`/requirement/manager-supply/${dataSource.id}`"
      slot="card"
      slot-scope="{ dataSource }"
      :dataSource="dataSource.requirement"
    >
      <status-box :status="dataSource.status" slot="status">
        <span v-if="dataSource.status === 1">待审核</span>
        <span v-else-if="dataSource.status === 10"> 专家审核中 </span>
        <span v-else-if="dataSource.status === 30"> 审核成功 </span>
        <span v-else-if="dataSource.status === 99">审核失败</span>
      </status-box>
    </ProCard>
  </StatusFilterList>
</template>

<script>
import ProCard from "./components/proCard.vue";
import { getOpSupplyList } from "@/api/api/requirement";
export default {
  components: { ProCard },
  data() {
    return {
      nameType: 0,
      params: {
        current: 1,
        limit: 5,
        total: 0,
        status: 0,
        name: "",
        filters: [
          { value: 1, name: "待审核" },
          { value: 10, name: "专家审核中" },
          { value: 30, name: "审核成功" },
          { value: 99, name: "审核失败" },
        ],
      },
      list: [],
    };
  },
  created() {
    this.change();
  },
  methods: {
    change(e) {
      const params = this.generateParams(e);
      this.getList(params);
    },
    generateParams(e) {
      Object.assign(this.params, e);

      const { name, status, limit, current } = this.params;

      const temp = {
        status: status ? status : undefined,
        limit,
        offset: limit * (current - 1),
      };

      if (this.nameType) {
        temp.company = name;
      } else {
        temp.name = name;
      }

      return temp;
    },
    getList(params) {
      getOpSupplyList(params)
        .then(({ count, rows }) => {
          this.list = rows;
          this.$set(this.params, "total", count);
          let current = this.params.current;
          if (rows.length === 0 && current !== 1) {
            console.log(current, "当前页面没有数据了");
            // this.$set(this.params, "current", --current);
            // this.change();
          }
        })
        .catch(() => {
          this.$message.error("获取列表失败");
        });
    },
  },
};
</script>